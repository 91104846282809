import React, { Component } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Line from '../assets/images/line-black.svg';
import Tick from '../assets/images/tick-black.svg';

const Feature = styled.li`
  ${tw`font-medium`}
  opacity:.2;
  margin-bottom: 14px !important;
  &:before {
    background: url(${Line}) no-repeat center !important;
  }

  &.has-feature {
    opacity: 1;
    &:before {
      background: url(${Tick}) no-repeat center !important;
    }
  }
`;

export default class PricingFeatureList extends Component {
  render() {
    return (
      <div>
        {this.props.data.length && (
          <div className="post-content my-6">
            <ul>
              {this.props.data.map(({ feature, hasFeature }, i) => (
                <Feature key={feature + i} className={[hasFeature ? 'has-feature' : 'no-feature', ' text-16px']}>
                  {feature}
                </Feature>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
