import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PricingFeatureList from '../components/PricingFeatureList';
import PricingItem from '../components/PricingItem';

export default class Pricing extends Component {
  render() {
    const { seo } = this.props.data.wpPage;
    const { pricingHero, pricing, faqs } = this.props.data.wpPage.pricingPage;
    return (
      <Layout>
        <SEO seo={seo} />

        <section className="md:py-32 py-18 text-center">
          <div className="container">
            <h1 className="text-58px">{pricingHero.heading}</h1>
          </div>
        </section>

        <section className="pricing mb-20">
          <div className="container">
            <div className="pricing-grid">
              {pricing.map((rs) => (
                <PricingItem key={rs.heading} rs={rs} />
              ))}
            </div>
          </div>
        </section>

        <section className="hidden md:block features my-24">
          <div className="container">
            <h2 className="border-b font-bold uppercase leading-loose mb-4">Features</h2>

            <div className="pricing-grid">
              {pricing.map((rs) => (
                <PricingFeatureList key={'features' + rs.heading} data={rs.features} />
              ))}
            </div>
          </div>
        </section>

        <section className="md:my-28 my-24 text-center md:text-left">
          <div className="container">
            <h2 className="text-58px text-center md:mb-18 mb-12">{faqs.heading}</h2>
            <div className="mx-auto grid md:grid-cols-2 lg:gap-20 gap-14" style={{ maxWidth: '1072px' }}>
              {faqs.items.map(({ question, answer }, i) => (
                <div key={question + i}>
                  <div className="text-28px font-semibold mb-3">{question}</div>
                  <div>{answer}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query Pricing {
    wpPage(slug: { eq: "pricing" }) {
      title
      ...SEO
      pricingPage {
        pricingHero {
          heading
        }

        pricing {
          benefits {
            bulletPoint
          }
          price
          button {
            target
            title
            url
          }
          colour
          features {
            feature
            hasFeature
          }
          heading
          icon {
            ...Image
          }
        }

        faqs {
          heading
          items {
            answer
            question
          }
        }
      }
    }
  }
`;
