import React, { useState } from 'react';
import Image from './Image';
import Link from './Link';
import PricingFeatureList from './PricingFeatureList';

export default function PricingItem({ rs }) {
  const [mobFeatsOpen, setMobFeatsOpen] = useState(false);
  return (
    <div>
      <div className="mx-auto max-h-full  lg:mb-12 mb-8" style={{ maxWidth: '50%' }}>
        <div className="o-ratio o-ratio--pricing-item mx-auto" style={{ maxWidth: '131px' }}>
          <div className="o-ratio__content">
            <Image data={rs.icon} className="mx-auto" />
          </div>
        </div>
      </div>
      <h2 className="border-b font-bold uppercase leading-loose">{rs.heading}</h2>
      <h4>
        <span className="text-72px font-bold my-6">{rs.price}</span>
        <span className="text-sm">/mo</span>
      </h4>

      {rs.benefits.length && (
        <div className="post-content md:mb-10 mb-6">
          <ul>
            {rs.benefits.map(({ bulletPoint }, i) => (
              <li key={rs.heading + bulletPoint + i}>{bulletPoint}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="md:hidden mb-10">
        <button onClick={() => setMobFeatsOpen(!mobFeatsOpen)} className="underline font-medium">
          {mobFeatsOpen ? 'Hide' : 'Show'} Features
        </button>
        {mobFeatsOpen && <PricingFeatureList key={'mobile-features' + rs.heading} data={rs.features} />}
      </div>

      {rs.button && (
        <Link to={rs.button.url} className="button button--black button--waves block w-full">
          {rs.button.title}
        </Link>
      )}
    </div>
  );
}
